import { MainMenu } from '@src/components/MainMenu'
import PageWrap from '@src/components/PageWrap'
import { PHONE_NUMBER } from '@src/constants'
import { Button, Flexbox, Link, Text } from '@stage-ui/core'
import { metrikaGoal } from '@src/utils/metrikaGoal'
import { SHLogo } from '@src/components/SHLogo'
import Logo from '@assets/images/logo.svg'

export const Header = () => {
  const isAgencyPage =
    typeof window !== 'undefined' && window.location.host === 'redsk.ai'

  return (
    <PageWrap p="1.5rem">
      <MainMenu
        items={
          [
            // { label: 'Продукт', href: "#" },
            // { label: 'Характеристики', href: "#" },
            // { label: 'Видео', href: "#" },
            // { label: 'О команде', href: "#" },
            // { label: 'Контакты', href: "#" },
          ]
        }
        logo={
          <Link
            href="/"
            onClick={() => {
              metrikaGoal('clickHeaderLogo')
            }}
          >
            {isAgencyPage ? <SHLogo /> : <img src={Logo} style={{ width: '10rem' }} />}
          </Link>
        }
        extra={
          <Flexbox flex={1} justifyContent="flex-end" alignItems="center">
            <Text>
              <Link
                weight={700}
                color="red700"
                size="1.25rem"
                href={`tel:${PHONE_NUMBER}`}
                onClick={() => {
                  metrikaGoal('clickHeaderPhone')
                }}
              >
                {PHONE_NUMBER}
              </Link>
            </Text>
            <a
              href="https://redsk.ai/bucket/docs/agreement_v1.pdf"
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                metrikaGoal('clickHeaderDownloadAgreement')
              }}
            >
              <Button
                display={['flex', 'none']}
                ml="xl"
                py="1.5rem"
                px="0.875rem"
                color="red800"
                borderColor={'gray300'}
                decoration="outline"
                borderRadius="0.5rem"
                uppercase
                style={{
                  fontWeight: 600,
                  fontSize: '0.875rem',
                }}
              >
                Договор-оферта
              </Button>
            </a>
          </Flexbox>
        }
      />
    </PageWrap>
  )
}
